// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-markdown-about-md": () => import("./../src/pages/markdown/about.md" /* webpackChunkName: "component---src-pages-markdown-about-md" */),
  "component---src-pages-markdown-index-md": () => import("./../src/pages/markdown/index.md" /* webpackChunkName: "component---src-pages-markdown-index-md" */),
  "component---src-pages-stuff-jsx": () => import("./../src/pages/stuff.jsx" /* webpackChunkName: "component---src-pages-stuff-jsx" */),
  "component---src-templates-page-jsx": () => import("./../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */)
}

